import React from "react";
import { Link } from "react-router-dom";

const FAQS = () => {
  return (
    <div className="FAQS mt-10">
      <div className="canvas">
        <h1 className="text-center fw-bold">Preguntas Frecuentes</h1>
        <div className="accordion mt-5" id="accordionExample">
          <div className="accordion-item rounded border-0">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button rounded fs-5"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                ¿Quiénes somos?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2 font2">
                Transferven es la plataforma elegida por la mayoría de los Venezolanos para sus envíos de Dinero y Criptomonedas. Somos un equipo de profesionales con más de 8 años en el sector de remesas hacia Venezuela y otros países de Latinoamérica. Como Empresa Regulada, cumplimos con los más estrictos Protocolos de Seguridad para evitar el fraude y ofrecer una experiencia 100% Segura a todos los usuarios.
              </div>
            </div>
          </div>
       
          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                ¿Qué es una Cuenta Transferven?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Es tu Panel de Control personal, desde donde podrás gestionar tus Envíos y revisar tu Historial de Transacciones 24/7/365.
              </div>
            </div>
          </div>


          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                ¿Cómo enviar dinero con Transferven?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Es muy sencillo. Abre tu Cuenta Transferven{" "}
                <a href="https://transferven.com/registro" target="_blank">
                  AQUÍ</a> o contacta directamente con Atención Personalizada a través de Whatsapp
                {" "}
                <a href="https://wa.me/34620818181" target="_blank">
                  AQUÍ
                </a>{". "}
                Podrás gestionar tus Envíos 24 Hrs. desde tu Panel de Control.
              </div>
            </div>
          </div>


          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingEight">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                ¿Cómo enviar en USDT y otras Criptomonedas?
              </button>
            </h2>
            <div
              id="collapseEight"
              className="accordion-collapse collapse"
              aria-labelledby="headingEight"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Muy sencillo. Abre tu Cuenta Transferven y con nuestro servicio Walletven podrás elegir la cantidad de Criptomonedas que quieres comprar e indicarnos la wallet para recibirlas.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingSeven">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                ¿Qué es Walletven?
              </button>
            </h2>
            <div
              id="collapseSeven"
              className="accordion-collapse collapse"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Walletven te permite comprar USDT y enviarlos directamente a Venezuela o cualquier país del mundo hacia la wallet que tú decidas. Podrás disponer de tus activos a través de CriptoCajeros, Tarjetas y P2P de Exchanges o pagos directos a otras wallets.
              </div>
            </div>
          </div>


          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                ¿Tipos de Entrega?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Transferimos directamente a la Cuenta Bancaria, Pago Móvil o Wallet del beneficiario que nos indiques. Trabajamos con todas las Entidades Bancarias de Venezuela y enviamos también a otros países de Latinoamérica.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingSix">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                ¿Cómo pago mi Envío?
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Podrás pagar a través de Transferencia Bancaria, Tarjeta, Paypal o Criptomonedas.
              </div>
            </div>
          </div>



          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                ¿Cómo contacto con Atención al Cliente?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Ponemos a tu disposición un Chat Online  <a href="https://wa.me/34620818181" target="_blank">
                  AQUÍ
                </a>, una sección para enviar Emails desde nuestra web y un Servicio Telefónico de Atención al Cliente al +34 91 993 13 12.
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default FAQS;
