import React, { useEffect } from "react";
import FAQS4 from "../Components/FAQS4";
import PageBanner from "../Components/PageBanner";
import HowWorks4 from "../Components/HowWorks4";
import img from "../assets/crypto.jpg";
import Calculator4 from "../Components/Calculator4";

const Home2 = () => {
  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  return (
    <div className="home2">
      <PageBanner title="Walletven" img={img} />
      <div className={`d-flex align-items-start mt-10 canvas 
     rounded p-3 justify-content-between ${window.matchMedia("(max-width: 850px)").matches && "flex-column"}`}>
        <HowWorks4 />
        <Calculator4 />
      </div>
      <FAQS4 />
      <div className="mt-5"></div>
      <div className="canvas bg-white shadow rounded p-3">
        <div className="col-md-10 col-12 mx-auto text-center">
      La inversión en criptoactivos no está regulada, puede no ser adecuada para inversores minoristas y perderse la totalidad del importe invertido.
Es importante leer y comprender los riesgos de esta inversión que se explican detalladamente en esta <a href="./Advertencia de riesgos de Criptoactivos TRANSFERVEN.pdf" target="_blank">ubicación</a>. 
        </div>
      </div>
    </div>
  );
};

export default Home2;
