import "./App.css";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom";
import Home from "./Pages/Home";
import Register from "./Pages/Register";
import About from "./Pages/About";
import Footer from "./Components/Footer";
import Contact from "./Pages/Contact";
import Profile from "./Pages/Profile";
import Login from "./Pages/Login";
import { useAuthContext } from "./Contexts/AuthContext";
import Forget from "./Pages/Forget";
import Forget2 from "./Pages/Forget2";
import ResetPassword from "./Pages/ResetPassword";
import OrderHistory from "./Pages/OrderHistory";
import Chat from "./Components/Chat";
import Payment from "./Pages/Payment";
import TextPage from "./Pages/textPage";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import Recogida from "./Pages/Recogida";
import Calcular from "./Pages/Calcular";
import Home2 from "./Pages/Home2";
import Home4 from "./Pages/Home4";
import Verify from "./Pages/Verify";
import Beneficiario from "./Pages/Beneficiario";
import Home3 from "./Pages/Home3";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [options, setOption] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { userdata, user, setCookies, cookies, setuserData } = useAuthContext();
  const [show, setshow] = useState(true);


  useEffect(() => {
    const getUser = async () => {
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/auth/user",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );
      setuserData(res.data);
    }
    getUser()
  }, [])

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <ToastContainer />
      {/* Same as */}
      {!cookies && location.pathname !== "/verify" && (
        <>
          {!options ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#2B373B",
                position: "fixed",
                bottom: "0%",
                zIndex: 5000000,
                color: "white",
                padding: "20px",
              }}
              className="cookie-main"
            >
              <div className="w-75 me-4 cookie-text">
                <p>
                  Usamos cookies y tecnologías similares para habilitar los
                  servicios y la funcionalidad de nuestro sitio web y para
                  comprender su interacción con nuestro servicio. Al hacer click
                  en Aceptar, se acepta que se utilicen dichas tecnologías para
                  marketing y estadísticas. <br />
                  <Link to="/cookies">Ver Política de Cookies.</Link>
                </p>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <button
                  className="btn btn-outline-light mt-3"
                  onClick={() => setOption(true)}
                >
                  Opciones de Cookies
                </button>
                <button
                  className="btn btn-light ms-3 mt-3"
                  onClick={() => {
                    Cookies.set("cookies", true, {
                      expires: 360,
                      secure: true,
                    });
                    setCookies(true);
                  }}
                >
                  Aceptar
                </button>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                justifyContent: "center",
                background: "#2B373B",
                position: "fixed",
                bottom: "0%",
                zIndex: 5000000,
                color: "white",
                padding: "20px 40px",
                height: "100vh",
                overflowY: "scroll",
              }}
            >
              <div>
                <GrClose
                  style={{
                    position: "absolute",
                    right: "15px",
                    top: "30px",
                    filter: "invert(100%)",
                  }}
                  cursor={"pointer"}
                  size={30}
                  onClick={() => setOption(false)}
                />
                <h4 className="my-1">Configuración avanzada de cookies</h4>
                <div className="mt-2 d-flex align-items-start justify-content-between">
                  <div>
                    <p className="fs-5">Cookies esenciales</p>
                    <p>
                      {" "}
                      Estas cookies permiten funciones básicas como la
                      seguridad, la verificación de la identidad y la
                      administración de la red. Estas cookies no pueden ser
                      desactivadas.{" "}
                    </p>
                  </div>

                  <div className="form-check form-switch" type="button">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      style={{
                        width: "50px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                      id="flexSwitchCheckDefault"
                      checked
                    />
                  </div>
                </div>
                <hr />
                <div className="mt-2 d-flex align-items-start justify-content-between">
                  <div>
                    <p className="fs-5">Habilitar las cookies de marketing</p>
                    <p>
                      {" "}
                      Estas cookies se utilizan para hacer un seguimiento de la
                      eficacia de la publicidad para proporcionar un servicio
                      más relevante y ofrecer mejores anuncios que se ajusten a
                      tus intereses.
                    </p>
                  </div>

                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      style={{
                        width: "50px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                </div>
                <hr />

                <div className="mt-2 d-flex align-items-start justify-content-between">
                  <div>
                    <p className="fs-5">Habilitar cookies funcionales</p>
                    <p>
                      {" "}
                      Estas cookies recopilan datos para recordar las elecciones
                      que los usuarios hacen, para mejorar y ofrecer una
                      experiencia más personalizada.
                    </p>
                  </div>

                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      style={{
                        width: "50px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                </div>
                <hr />

                <div className="mt-2 d-flex align-items-start justify-content-between">
                  <div>
                    <p className="fs-5">
                      Habilitar las cookies de estadísticas
                    </p>
                    <p>
                      {" "}
                      Estas cookies nos ayudan a entender cómo los visitantes
                      interactúan con nuestro sitio web, descubren errores y
                      proporcionan mejores estadísticas generales.
                    </p>
                  </div>

                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      style={{
                        width: "50px",
                        height: "22px",
                        cursor: "pointer",
                      }}
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                </div>
                <hr />

                <button
                  className="btn px-3 mb-4 btn-light d-block ms-auto"
                  onClick={() => {
                    Cookies.set("cookies", true);
                    setCookies(true);
                  }}
                >
                  Guardar
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {user && userdata && Object.values(userdata).length < 12 && (
        <div
          className="d-flex align-items-center justify-content-around 
          alert alert-danger w-100 mb-0 rounded-0"
          role="alert"
          style={{ zIndex: 2000000, height: "70px" }}
        >
          <span className="alert_text">
            ¡Aviso! Completa tus datos personales.
          </span>
          <p
            className="continue w-auto fs-6 px-4 py-2 mt-0"
            style={{ whiteSpace: "nowrap" }}
            target="_blank"
            type="button"
            onClick={() => navigate(`/profile?edit=true`)}
          >
            Completar
          </p>
        </div>
      )}

      {show && userdata && <Chat />}
      <Routes>
      <Route path="/beneficiarios" element={<Beneficiario />} />
        {show ? (
          <>
            <Route path="/verify" element={<Verify />} />
            <Route path="/" element={<Home />} />
            {/* <Route path="/crypto" element={<Home2 />} /> */}
            <Route path="/walletven" element={<Home4 />} />
            <Route path="/recargas" element={<Home3 />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/registro" element={<Register />} />
            <Route path="/forgotpassword" element={<Forget />} />
            <Route path="/reset-password" element={<Forget2 />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/login/:id" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/prealertar" element={<Payment />} />
            <Route path="/calcular" element={<Calcular />} />
            {/* <Route path="/beneficiarios" element={<Beneficiario />} /> */}

            <Route
              path="/privacy"
              element={
                <TextPage
                  title={"Política de Privacidad"}
                  text={
                    <>
                    <p>
  Política de privacidad 
</p>
<p>
  Objeto 
</p>
<p>
  La presente página Web ha sido diseñada para dar a conocer los servicios ofertados por la empresa EURO DIGITAL MULTISERVICES SLU con CIF B-13852330, con domicilio social en C/Garcilaso De La Vega 21, Local 12, 38005. Santa Cruz de Tenerife, España.
</p>
<p>
  Propiedad intelectual e industrial 
</p>
<p>
  Los derechos de propiedad intelectual de la página WWW.TRANSFERVEN.COM, su código fuente, diseño, estructuras de navegación y los distintos elementos en ella contenidos son titularidad de EURO DIGITAL MULTISERVICES SLU, a quien corresponde el ejercicio exclusivo de los derechos de explotación de los mismos en cualquier forma y, en especial, los derechos de reproducción, distribución, comunicación pública y transformación, de acuerdo con la legislación española y de la Unión Europea aplicable.
</p>
<p>
  Contenidos 
</p>
<p>
  Se facilita a través de esta Web información acerca de servicios destinados a conocimiento público que en todo caso se sujetarán a los términos y condiciones expresamente detallados en cada momento y que son accesibles desde esta página Web, los cuales se sujetarán a las distintas disposiciones legales de aplicación.
</p>
<p>
  Acceso y uso 
</p>
<p>
  Tanto el acceso a esta página Web, como el uso que pueda hacerse de la información y contenidos incluidos en la misma, será de la exclusiva responsabilidad de quien lo realice. Las condiciones de acceso a este Web estarán supeditadas a la legalidad vigente y los principios de la buena fe y uso lícito por parte del usuario de la misma, quedando prohibido con carácter general cualquier tipo de actuación en perjuicio de EURO DIGITAL MULTISERVICES SLU. Se considerará terminantemente prohibido el uso de la presente página Web con fines ilegales o no autorizados.
</p>
<p>
  Responsabilidad 
</p>
<p>
  EURO DIGITAL MULTISERVICES SLU no se hace responsable bajo ningún concepto por ningún tipo de daño que pudiesen ocasionar los Usuarios a la presente página Web, o a cualquier otra, por el uso ilegal o indebido de la misma, o de los contenidos e informaciones accesibles o facilitadas a través de ella.
</p>
<p>
  Servicio 
</p>
<p>
  EURO DIGITAL MULTISERVICES SLU se reserva el derecho de suspender el acceso a su página Web, sin previo aviso, de forma discrecional y temporal, por razones técnicas o de cualquier otra índole, pudiendo asimismo modificar unilateralmente tanto las condiciones de acceso, como la totalidad o parte de los contenidos en ella incluidos.
</p>
<p>
  Generales 
</p>
<p>
  Para toda cuestión litigiosa o que incumba a la Página Web de WWW.TRANSFERVEN.COM, será de aplicación la legislación española, siendo competentes para la resolución de todos los conflictos derivados o relacionados con el uso de esta página Web, los Juzgados y Tribunales del domicilio del usuario. El acceso a la página Web de WWW.TRANSFERVEN.COM implica la aceptación de todas las condiciones anteriormente expresadas.
</p>
<p>
  Hiperenlaces 
</p>
<p>
  Los hiperenlaces contenidos en el sitio web de WWW.TRANSFERVEN.COM pueden dirigir a páginas web de terceros. WWW.TRANSFERVEN.COM no asume ninguna responsabilidad por el contenido, informaciones o servicios que pudieran aparecer en dichos sitios, que tendrán exclusivamente carácter informativo y que en ningún caso implican relación alguna entre WWW.TRANSFERVEN.COM y a las personas o entidades titulares de tales contenidos o titulares de los sitios donde se encuentren.
</p>

                    </>
                  }
                />
              }
            />
            <Route
              path="/avisolegal"
              element={
                <TextPage
                  title={"Aviso Legal"}
                  text={
                    <>
                   <p>
  Aviso legal
</p>
<p>
  Ley Protección de Datos de Carácter Personal
</p>
<p>
  De conformidad con lo establecido en Reglamento (UE) 2016/679 del Parlamento Europeo de Protección de Datos de Carácter Personal, y de la Ley Orgánica 3/2018, de 5 de diciembre (LOPDGDD), se informa al Cliente/Usuario de que los datos personales que nos facilita serán incluidos en un Registro de Actividades titularidad de EURO DIGITAL MULTISERVICES SLU, con la finalidad de realizar una correcta gestión de la relación comercial, así como para informarle de novedades, productos y/o servicios tanto por medios escritos como electrónicos.
</p>
<p>
  Vd. podrá en cualquier momento revocar el consentimiento prestado para el envío de comunicaciones comerciales electrónicas remitiendo un correo a la dirección info.es@transferven.com
</p>
<p>
  Finalmente, le informamos de que puede ejercer sus derechos de acceso, rectificación, cancelación y/u oposición, limitación de tratamiento, y portabilidad, así como revocación del consentimiento prestado, dirigiéndose por escrito a EURO DIGITAL MULTISERVICES SLU sita en C/Garcilaso De La Vega 21, Local 12, 38005. Santa Cruz de Tenerife, España.
</p>
<p>
  Ley de Servicios de la Información y Comercio Electrónico
</p>
<p>
  Según la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico, se comunican los siguientes datos sobre el dominio de WWW.TRANSFERVEN
</p>
<p>
  Titular del Dominio
</p>
<p>
  EURO DIGITAL MULTISERVICES SLU
</p>
<p>
  CIF B-13852330
</p>
<p>
  C/Garcilaso De La Vega 21, Local 12, 38005. Santa Cruz de Tenerife, España.
</p>
<p>
  Correo electrónico: info.es@transferven.com
</p>
<p>
  Nombre del dominio
</p>
<p>
  WWW.TRANSFERVEN.COM
</p>

                    </>
                  }
                />
              }
            />
            <Route
              path="/cookies"
              element={
                <TextPage
                  title={"Política de Cookies"}
                  text={
                    <div>
                    <p>Cláusula Cookies</p>
                    <p>En EURO DIGITAL MULTISERVICES SLU utilizamos cookies con el objetivo de prestar un mejor servicio y proporcionarte una mejor experiencia en tu navegación. Queremos informarte de manera clara y precisa sobre las cookies que utilizamos, detallando a continuación, qué es una cookie, para qué sirve, qué tipos de cookies utilizamos, cuáles son su finalidad y cómo puedes configurarlas o deshabilitarlas si así lo deseas.</p>
                    <p>¿Qué es una cookie y para qué sirve?</p>
                    <p>Una Cookie es un pequeño archivo que se almacena en el ordenador del usuario, tablet, smartphone o cualquier otro dispositivo con información sobre la navegación.</p>
                    <p>El conjunto de «cookies» de todos nuestros usuarios nos ayuda a mejorar la calidad de nuestra web, permitiéndonos controlar qué páginas son útiles, cuáles no y cuáles son susceptibles de mejora.</p>
                    <p>Las cookies son esenciales para el funcionamiento de internet, aportando innumerables ventajas en la prestación de servicios interactivos, facilitándote la navegación y usabilidad de nuestra web.</p>
                    <p>En ningún caso las cookies podrían dañar tu equipo. Por contra, el que estén activas nos ayuda a identificar y resolver los errores.</p>
                    <p>¿Qué tipos de cookies utilizamos?</p>
                    <p>Según la entidad que la gestiona:</p>
                    <p>Cookies propias: Son aquellas que se envían a tu equipo desde nuestros propios equipos o dominios y desde el que prestamos el servicio que nos solicitas.</p>
                    <p>Cookies de terceros: Son aquellas que se envían a tu equipo desde un equipo o dominio que no es gestionado por nosotros, sino por otra entidad colaboradora. Como, por ejemplo, las usadas por redes sociales, o por contenido externo como Google Maps.</p>
                    <p>Según el plazo de tiempo que permanecen activadas:</p>
                    <p>Cookies de sesión: Son cookies temporales que permanecen en el archivo de cookies de tu navegador hasta que abandonas la página web, por lo que ninguna queda registrada en el disco duro de tu ordenador. La información obtenida por medio de estas cookies, sirven para analizar pautas de tráfico en la web. A la larga, esto nos permite proporcionar una mejor experiencia para mejorar el contenido y facilitar su uso.</p>
                    <p>Cookies persistentes: son almacenadas en el disco duro y nuestra web las lee cada vez que realizas una nueva visita. Una web permanente posee una fecha de expiración determinada. La cookie dejará de funcionar después de esa fecha. Estas cookies las utilizamos, generalmente, para facilitar los servicios de compra y registro.</p>
                    <p>Según su finalidad:</p>
                    <p>Cookies técnicas: Son aquellas necesarias para la navegación y el buen funcionamiento de nuestra página web. Permiten, por ejemplo, controlar el tráfico y la comunicación de datos, acceder a partes de acceso restringido, realizar el proceso de compra de un pedido, utilizar elementos de seguridad, almacenar contenidos para poder difundir vídeos o compartir contenidos a través de redes sociales.</p>
                    <p>Cookies de personalización: Son aquéllas que te permiten acceder al servicio con unas características predefinidas en función de una serie de criterios, como por ejemplo el idioma, el tipo de navegador a través del cual se accede al servicio, la configuración regional desde donde se accede al servicio, etc.</p>
                    <p>Cookies de análisis: Son aquéllas que nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios de los servicios prestados. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos y servicios que ofrecemos.</p>
                    <p>Cookies publicitarias: Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que se pudieran incluir en nuestra página web.</p>
                    <p>Cookies de publicidad comportamental: Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada. Gracias a ellas, podemos conocer los hábitos de navegación en internet y mostrarte publicidad relacionada con tu perfil de navegación.</p>
                    <p>Finalidad de cada una de las cookies utilizadas:</p>
                    <p>A continuación, detallamos las cookies utilizadas en nuestra web y la finalidad de las mismas.</p>
                    <p>Relación de cookies utilizadas en el dominio WWW.TRANSFERVEN.COM:</p>
                    <p>NOMBRE: PHPSESSID</p>
                    <p>FINALIDAD: COOKIE DE SESIÓN</p>
                    <p>PROPIA/TERCEROS: PROPIA</p>
                    <p>EXCLUIDA/NO EXCLUIDA DEL DEBER DE LA INFORMACIÓN Y CONSENTIMIENTO: NO EXCLUIDA</p>
                    <p>Relación de terceros prestadores de servicios de cookies en el dominio WWW.TRANSFERVEN.COM:</p>
                    <p>NOMBRE PROVEEDOR: GOOGLE</p>
                    <p>FINALIDAD: ANÁLISIS Y TÉCNICAS</p>
                    <p>DESCRIPCIÓN DE LA FINALIDAD DE LA COOKIE: RECOPILAR INFORMACIÓN, INCLUIDA LA IP DEL USUARIO, QUE SERÁ TRANSMITIDA, TRATADA Y ALMACENADA POR GOOGLE EN LOS TÉRMINOS Y CONDICIONES FIJADOS EN LA WEB google.com</p>
                    <p>NOTA: Esta lista se actualizará con la mayor celeridad posible a medida que cambien los servicios del sitio web ofrecidos en el mismo. Sin embargo, ocasionalmente durante esta actualización puede ser que la lista no incluya ya una cookie, aunque siempre se referirá a cookies con propósitos idénticos a los registrados en esta lista.</p>
                    <p>¿Cómo puedes configurar o deshabilitar tus cookies?</p>
                    <p>Puedes permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones de tu navegador de Internet. En caso de que no permitas la instalación de cookies en tu navegador es posible que no puedas acceder a algunos de los servicios y que tu experiencia en nuestra web pueda resultar menos satisfactoria.</p>
                  </div>
                                
                  }
                />
              }
            />
            <Route
              path="/protection"
              element={
                <TextPage
                  title={"Protección de Datos"}
                  text={
                    <>
                 <div>
  <p>Política de Protección de datos</p>
  <p>Finalidad del tratamiento</p>
  <p>En función del formulario que en cada caso complete el Usuario, los datos podrán ser tratados con las siguientes finalidades:</p>
  <p>Usuarios registrados: La información que nos proporcione en el formulario de registro se utiliza para la gestión de Usuarios de la página web, con el fin de que pueda acceder a su perfil, consultar sus pedidos y realizar sus compras sin que tenga que introducir de nuevo sus datos identificativos (nombre, apellidos, email y teléfono) y domicilio de entrega. Puede modificar la configuración de su perfil siempre que quiera. EURO DIGITAL MULTISERVICES SLU no admitirá intentos de registros falsos o que suplanten la identidad de personas o empresas, el suministro de información falsa en el formulario de registro implicará la baja automática del Usuario. EURO DIGITAL MULTISERVICES SLU se reserva el derecho de verificar la información facilitada por el Usuario mediante comprobación telefónica.</p>
  <p>Newsletter: En los casos en los que nos otorgue su consentimiento según lo previsto en el artículo 21 de la Ley de Servicios de la Sociedad de la Información, utilizaremos sus datos identificativos y de contacto para enviarle información sobre novedades de la página web, noticias de interés, así como promociones y ofertas de nuestros productos y servicios. Los canales que utilizamos habitualmente son: correo electrónico, correo postal, teléfono, SMS, mensaje Push, pero solo si usted nos presta su consentimiento, y teniendo siempre la posibilidad de darse de baja de manera cómoda y gratuita.</p>
  <p>Contacto: Los datos personales que nos proporcione a través del formulario de contacto se tratarán únicamente para atender su consulta o petición. La información recabada se limita a datos identificativos y de contacto, así como datos relacionados con la naturaleza de la petición que nos dirija.</p>
  <p>Formulario de reclamaciones: Los datos personales que nos proporcione a través del formulario se tratarán únicamente para atender su reclamación.</p>
  <p>Formulario de devoluciones: Los datos personales que nos proporcione a través del formulario se tratarán para la evaluación y gestión de la devolución o cambio solicitado y la recogida del producto si fuera necesario.</p>
  <p>Otras finalidades: La información facilitada se puede utilizar ocasionalmente para: Encuestas para mejorar nuestros servicios, gestionar las solicitudes de información, sugerencias y reclamaciones de clientes para su gestión y resolución.</p>
  
  <p>Plazo de conservación</p>
  <p>Los datos personales proporcionados se conservarán durante el plazo correspondiente para cumplir con las obligaciones legales, mientras no se oponga al tratamiento o revoque el consentimiento.</p>
  
  <p>Legitimación</p>
  <p>EURO DIGITAL MULTISERVICES SLU está legitimado al tratamiento de datos personales, en base al consentimiento otorgado por el interesado mediante la firma o aceptación de los pertinentes formularios, para uno o varios fines específicos, tal y como recoge el artículo 6.1. a) del Reglamento General de Protección de datos personales.</p>
  
  <p>Exactitud de los datos</p>
  <p>Por otro lado, con el fin de que los datos obrantes en nuestros ficheros, informáticos y/o en papel, siempre correspondan a la realidad, se tratará de mantener actualizados. De manera que, a estos efectos, el Usuario deberá realizar los cambios, directamente, cuando así esté habilitado o comunicándose, por medio fehaciente, al área o departamento correspondiente de EURO DIGITAL MULTISERVICES SLU.</p>
  
  <p>Destinatarios</p>
  <p>Los datos personales no serán cedidos o comunicados a terceros, salvo en los supuestos necesarios para el desarrollo, control y cumplimiento de la/s finalidad/es expresada/s, en los supuestos previstos según Ley.</p>
  
  <p>Derechos de los usuarios</p>
  <p>No obstante, el interesado de los datos personales en todo caso podrá ejercitar los derechos que le asisten, de acuerdo con el Reglamento General de Protección de Datos, y que son:</p>
  <p>Derecho a solicitar el acceso a los datos personales relativos al interesado, Derecho a solicitar su rectificación o supresión, Derecho a solicitar la limitación de su tratamiento, Derecho a oponerse al tratamiento, Derecho a la portabilidad de los datos.</p>
  <p>El interesado podrá ejercitar tales derechos mediante solicitud acompañada de una fotocopia de su D.N.I, y en la que especificará cuál de éstos solicita sea satisfecho, remitida a la dirección:</p>
  <p>EURO DIGITAL MULTISERVICES SLU</p>
  <p>CIF B-13852330</p>
  <p>C/Garcilaso De La Vega 21, Local 12, 38005. Santa Cruz de Tenerife, España.</p>
  <p>Correo electrónico: info.es@transferven.com</p>
  <p>En caso de considerar vulnerado su derecho a la protección de datos personales, podrá interponer una reclamación ante la Agencia Española de Protección de Datos (www.agpd.es).</p>
  
  <p>Medidas de seguridad</p>
  <p>Finalmente se informa que EURO DIGITAL MULTISERVICES SLU, adoptará en su sistema de información las medidas técnicas y organizativas adecuadas, dando cumplimiento al principio de responsabilidad proactiva, a fin de garantizar la seguridad y confidencialidad de los datos almacenados, evitando así, su alteración, pérdida, tratamiento o acceso no autorizado; teniendo en cuenta el estado de la técnica, los costes de aplicación, y la naturaleza, el alcance, el contexto y los fines del tratamiento, así como riesgos de probabilidad y gravedad variables asociadas a cada uno de los tratamientos.</p>
</div>

                    </>
                  }
                />
              }
            />

            <Route
              path="/terms"
              element={
                <TextPage
                  title={"Términos y Condiciones"}
                  text={
                    <>
                      <p align="JUSTIFY">
                        <span
                          style={{ fontFamily: "'Times New Roman', serif" }}
                        >
                          <span style={{ fontSize: "medium" }}>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                1.Sobre Nosotros
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span
                          style={{ fontFamily: "'Times New Roman', serif" }}
                        >
                          <span style={{ fontSize: "medium" }}>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                EURO DIGITAL MULTISERVICES SLU (en lo sucesivo,
                                " TRANSFERVEN ") con CIF B13852330 y domicilio
                                social en C/Garcilaso De La Vega 21, Local 12, 38005. Santa Cruz de Tenerife, España. TRANSFERVEN es responsable del
                                desarrollo, funcionamiento y contenido publicado
                                en su web (referida a continuaci&oacute;n como
                                &ldquo;la web de TRANSFERVEN&rdquo; o &ldquo;la
                                web&rdquo;) a la que se puede acceder desde la
                                siguiente direcci&oacute;n web:&nbsp;
                                <a href="https://www.latinotransfer.com/">
                                  https://www.transferven.com/
                                </a>
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              2.Alcance de los T&eacute;rminos de Uso
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Los presentes T&eacute;rminos de Uso regulan el
                            acceso a la web de TRANSFERVEN y el uso de la
                            informaci&oacute;n y servicios proporcionados a
                            trav&eacute;s de la misma. En consonancia con
                            nuestra&nbsp;
                          </span>
                        </span>
                        <a href="https://www.transferven.com/privacy" target="_blank">
                          <span style={{ fontFamily: "Arial, serif" }}>
                            <span>
                              <u>Pol&iacute;tica de Privacidad</u>
                            </span>
                          </span>
                        </a>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            &nbsp;y nuestra&nbsp;
                          </span>
                        </span>
                        <a href="https://www.transferven.com/cookies" target="_blank">
                          <span style={{ fontFamily: "Arial, serif" }}>
                            <span>
                              <u>Pol&iacute;tica de Uso de Cookies</u>
                            </span>
                          </span>
                        </a>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            , que regulan expl&iacute;citamente y el uso de los
                            datos personales recogidos a los clientes online, y
                            conforman la relaci&oacute;n contractual entre
                            TRANSFERVEN
                          </span>
                        </span>
                        &nbsp;
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            y los usuarios de la web de TRANSFERVEN (referidos
                            en adelante como &ldquo;los usuarios de la
                            web&rdquo; o &ldquo;clientes&rdquo;). Las presentes
                            Condiciones ser&aacute;n de aplicaci&oacute;n desde
                            el inicio del procedimiento de contrataci&oacute;n
                            online, hasta la finalizaci&oacute;n del servicio, o
                            de los plazos de desistimiento y garant&iacute;as
                            legalmente establecidas, seg&uacute;n proceda.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Los usuarios de la web pueden aceptar
                            t&eacute;rminos de uso espec&iacute;ficos aplicables
                            a servicios financieros y disponibles en la web de
                            TRANSFERVEN. Estos t&eacute;rminos adicionales
                            ser&aacute;n notificados a los usuarios de la web
                            cuando soliciten los servicios a los que resulten
                            aplicables y requerir&aacute;n su consentimiento por
                            separado.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Los T&eacute;rminos de Uso aplicables a la
                            sesi&oacute;n durante la cual los usuarios utilicen
                            la web de TRANSFERVEN son aquellos publicados al
                            mismo tiempo en la web. Por lo tanto, recomendamos a
                            los usuarios que impriman o descarguen una copia de
                            los T&eacute;rminos de Uso aplicables a las
                            operaciones ejecutados a trav&eacute;s de la web y
                            los conserven para referencias futuras.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            La nulidad de una o varias provisiones de los
                            presentes T&eacute;rminos de Uso no afectar&aacute;n
                            a la validez, alcance o aplicabilidad de las
                            dem&aacute;s.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Las provisiones de los presentes T&eacute;rminos de
                            Uso se aplican sin perjuicio de cualquier
                            provisi&oacute;n legal que pueda resultar
                            tambi&eacute;n aplicable.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              3.Informaci&oacute;n y servicios disponibles en la
                              p&aacute;gina web
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span
                          style={{ fontFamily: "'Times New Roman', serif" }}
                        >
                          <span style={{ fontSize: "medium" }}>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                La informaci&oacute;n y los servicios
                                financieros disponibles en la web de&nbsp;
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                TRANSFERVEN
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                &nbsp;est&aacute;n destinados &uacute;nicamente
                                a ser utilizados por&nbsp;individuos mayores de
                                18 a&ntilde;os con residencia legal en
                                Espa&ntilde;a &ndash; o al menos con
                                instrumentos de pago emitidos en Espa&ntilde;a
                                (o en otro pa&iacute;s de la EU donde la
                                plataforma se encuentre
                                operativa).&nbsp;Mediante el uso de los
                                productos y servicios proporcionados en la web
                                de&nbsp;
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                TRANSFERVEN
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                , el usuario confirma que cumple con los
                                criterios mencionados anteriormente de edad y
                                residencia &ndash; o territorialidad de los
                                medios de pago que usen en la web.
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span
                          style={{ fontFamily: "'Times New Roman', serif" }}
                        >
                          <span style={{ fontSize: "medium" }}>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                La informaci&oacute;n publicada en la web
                                de&nbsp;
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                TRANSFERVEN
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                &nbsp;se proporciona sin garant&iacute;a de
                                responsabilidad por parte de&nbsp;
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                TRANSFERVEN
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                , excepto en caso de negligencia clara y grave
                                por parte de&nbsp;
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                TRANSFERVEN
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                . Solo ser&aacute; v&aacute;lida en la fecha de
                                su publicaci&oacute;n en la web y&nbsp;
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                TRANSFERVEN
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                &nbsp;no puede garantizar su precisi&oacute;n,
                                ausencia de errores, integridad, y/o
                                perdurabilidad.
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span
                          style={{ fontFamily: "'Times New Roman', serif" }}
                        >
                          <span style={{ fontSize: "medium" }}>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                La informaci&oacute;n, simulaciones y tasas
                                disponibles en la web de&nbsp;
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                TRANSFERVEN
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                &nbsp;se proporcionan exclusivamente a efectos
                                informativos e independientemente de cualquier
                                obligaci&oacute;n contractual espec&iacute;fica
                                de&nbsp;
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                TRANSFERVEN
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                . No pueden considerarse como una
                                recomendaci&oacute;n o una oferta directa de la
                                venta y/o venta de productos y servicios, a
                                menos que est&eacute;n acompa&ntilde;ados de una
                                orden de compra validada por el usuario de la
                                web. Por lo tanto, solamente conforman un
                                elemento de an&aacute;lisis para los usuarios de
                                la web, quienes son libres de decidir y los
                                &uacute;nicos responsables del uso que hagan de
                                ellos.
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span
                          style={{ fontFamily: "'Times New Roman', serif" }}
                        >
                          <span style={{ fontSize: "medium" }}>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                TRANSFERVEN
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                &nbsp;toma todas las medidas razonables para
                                proporcionar informaci&oacute;n actualizada y
                                precisa en su web.&nbsp;
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                TRANSFERVEN
                              </span>
                            </span>
                            <span style={{ fontFamily: "Arial, serif" }}>
                              <span>
                                &nbsp;puede modificar en cualquier momento y sin
                                previo aviso los productos y servicios
                                disponibles en su web, o interrumpir el acceso a
                                cualquier tercero a su web, entre otros para
                                actualizar su contenido, sin ning&uacute;n tipo
                                de limitaci&oacute;n.
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              4.Registro y acceso a la zona segura de
                              transacciones
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Para el acceso a las &aacute;reas seguras de la web
                            de TRANSFERVEN y el uso de los servicios financieros
                            en l&iacute;nea proporcionados en ellas, el usuario
                            de la web debe registrarse proporcionando detalles
                            de identificaci&oacute;n personal, y confirmar su
                            email.&nbsp;
                          </span>
                        </span>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              Aceptando registrarse en la web de TRANSFERVEN, el
                              usuario de la web declara que acepta los
                              t&eacute;rminos de la Pol&iacute;tica de
                              Privacidad de TRANSFERVEN, que rige la
                              recolecci&oacute;n y el uso de los datos
                              personales de TRANSFERVEN. El usuario de la web
                              tambi&eacute;n declara tener al menos 18
                              a&ntilde;os y residir legalmente en Espa&ntilde;a.
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            El usuario de la web debe proporcionar
                            informaci&oacute;n precisa y veraz cuando se
                            registre en la web. Debe ser tambi&eacute;n
                            responsable de actualizar los datos personales
                            proporcionados a TRANSFERVEN, especialmente en casos
                            de cambios de residencia, documento de identidad,
                            n&uacute;mero de tel&eacute;fono y/o
                            direcci&oacute;n de email.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            TRANSFERVEN puede solicitar en cualquier momento a
                            los usuarios registrados que proporcionen documentos
                            que demuestren su identidad o su direcci&oacute;n de
                            residencia. Cuando el uso de ciertos servicios en
                            l&iacute;nea lo requiera, el usuario podr&aacute;
                            tambi&eacute;n aceptar proporcionar una copia de su
                            documento de identidad y/u otros documentos que sean
                            necesarios para proporcionar dichos servicios. La
                            web mostrar&aacute; un mensaje invitando al usuario
                            a cargar una copia digital de su documento de
                            identidad y cualquier otra documentaci&oacute;n de
                            soporte a su ficha, cuando sea necesario.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Cada usuario web podr&aacute; tener una &uacute;nica
                            cuenta con TRANSFERVEN. Los medios de acceso a la
                            misma son personales e intransferibles y no deben
                            ser compartidos con terceros, incluyendo a
                            familiares.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            El uso de la web de TRANSFERVEN y los servicios
                            financieros proporcionados a trav&eacute;s de
                            &eacute;sta est&aacute;n previstos exclusivamente
                            para uso privado y legal. En caso de que un usuario
                            quiera utilizar los servicios online de TRANSFERVEN
                            para uso empresarial, deber&aacute; dirigirse al
                            servicio de atenci&oacute;n al cliente a
                            trav&eacute;s de la informaci&oacute;n de contacto.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            El usuario se obliga a:
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Abonar el precio de los servicios y a hacer un uso
                            del mismo conforme a la ley y las presentes
                            condiciones generales.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Mantener la confidencialidad de su contrase&ntilde;a
                            y a notificarnos, de forma inmediata, su sospecha de
                            cualquier uso no autorizado de su cuenta o el acceso
                            a su contrase&ntilde;a.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            No utilizar la cuenta, el nombre de usuario o la
                            contrase&ntilde;a de otro Usuario; suplantando al
                            mismo.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            TRANSFERVEN se reserva el derecho a restringir en
                            cualquier momento el acceso a una parte o a la
                            totalidad de la web, as&iacute; como a cancelar una
                            transacci&oacute;n pendiente en cualquiera de los
                            siguientes casos:
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            El cliente proporciona datos de
                            identificaci&oacute;n incorrectos o incompletos;
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            El cliente se niega a proporcionar documentos de
                            identificaci&oacute;n v&aacute;lidos;
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            El cliente usa los servicios de la web para fines
                            fraudulentos o ilegales;
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            El cliente viola otras condiciones indicadas en los
                            presentes T&eacute;rminos de Uso;
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            El cliente arriesga la integridad, la seguridad o la
                            reputaci&oacute;n de la web.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              5.Seguridad y administraci&oacute;n de la cuenta
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Como parte del proceso de registro, los usuarios de
                            la web deber&aacute;n elegir una combinaci&oacute;n
                            de usuario y contrase&ntilde;a, que
                            constituir&aacute; en adelante su c&oacute;digo de
                            acceso personal para el &aacute;rea segura de
                            transacciones de la web. Este c&oacute;digo de
                            acceso es estrictamente personal y confidencial. Los
                            clientes son los &uacute;nicos responsables de su
                            uso y deber&aacute;n abstenerse de
                            proporcion&aacute;rselos a cualquier tercero.&nbsp;
                          </span>
                        </span>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              El c&oacute;digo de acceso personal deber&aacute;
                              introducirse &uacute;nicamente en el &aacute;rea
                              segura de la web de TRANSFERVEN (secci&oacute;n
                              &ldquo;ENTRAR del siguiente
                              enlace:&nbsp;https://transferven.com/login). Ni
                              TRANSFERVEN ni sus empleados le solicitar&aacute;n
                              en ning&uacute;n caso que revele su c&oacute;digo
                              de acceso a la web por tel&eacute;fono o por
                              email. Los clientes deben ignorar y reportar
                              inmediatamente a TRANSFERVEN cualquier
                              situaci&oacute;n en la que se les solicite su
                              c&oacute;digo de acceso por email o a
                              trav&eacute;s de la web de un tercero.
                            
                          </span>
                        </span>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            &nbsp;
                          </span>
                        </span>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Los usuarios de la web son tambi&eacute;n
                            responsables de asegurar que sus c&oacute;digos de
                            acceso no est&eacute;n guardados en los exploradores
                            o navegadores web ni registrados de ninguna otra
                            forma en un disco dura en su ordenador personal, o
                            en cualquier otro dispositivo f&aacute;cilmente
                            accesible a terceros.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Los usuarios registrados en la web podr&aacute;n en
                            cualquier momento solicitar la modificaci&oacute;n o
                            suspensi&oacute;n de sus c&oacute;digos de acceso a
                            la web de TRANSFERVEN. Deber&aacute;n tambi&eacute;n
                            informar inmediatamente a TRANSFERVEN en caso de que
                            detecten el uso no autorizado de sus c&oacute;digos
                            de acceso, as&iacute; como cualquier otra brecha en
                            la seguridad de su cuenta de usuario. TRANSFERVEN
                            tratar&aacute; de modificar o suspender de forma
                            inmediata los c&oacute;digos de acceso personal del
                            usuario registrado una vez que reciba tal
                            solicitud.&nbsp;
                          </span>
                        </span>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              Desde ese momento y excepto en caso de imprudencia
                              grave o dolo por parte de TRANSFERVEN, los
                              usuarios ser&aacute;n los &uacute;nicos
                              responsables por cualquier da&ntilde;o directo o
                              indirecto que pueda resultar, contra ellos, contra
                              TRANSFERVEN o contra cualquier tercero, por
                              cualquier uso, fraudulento o no, realizado por
                              ellos o por un tercero, de sus c&oacute;digos de
                              acceso en la web de TRANSFERVEN.
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              6.Exclusi&oacute;n de Responsabilidad
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Excepto en los casos de negligencia grave o dolo por
                            parte de TRANSFERVEN, &eacute;sta no ser&aacute;
                            responsable de ning&uacute;n da&ntilde;o directo o
                            indirecto contra el usuario de la web que resulten
                            de la web o de la imposibilidad del mismo,
                            cualquiera que sea la causa.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            TRANSFERVEN por lo tanto no ser&aacute; responsable
                            de los da&ntilde;os directos o indirectos causados
                            por, entre otros:
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            La interrupci&oacute;n del acceso a la web y/o
                            retrasos o p&eacute;rdidas de informaci&oacute;n
                            causadas por interrupciones en los sistemas de
                            comunicaci&oacute;n que no est&aacute;n bajo el
                            control de TRANSFERVEN (como cortes de electricidad,
                            interrupci&oacute;n de l&iacute;neas
                            telef&oacute;nicas o problemas causados por
                            proveedor de internet del usuario o por un software
                            espec&iacute;fico instalado en el ordenador del
                            usuario;
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            El acceso no autorizado y/o fraudulento de la web
                            usando los c&oacute;digos de acceso personales del
                            usuario, posibilitados por una negligencia del
                            usuario (causados, por ejemplo, por la
                            protecci&oacute;n inadecuada de la confidencialidad
                            de sus c&oacute;digos de acceso contra robo o la
                            transmisi&oacute;n de virus inform&aacute;ticos);
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            El uso fraudulento de una tarjeta de pago en la web
                            de TRANSFERVEN;
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Una situaci&oacute;n de fuerza mayor.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            TRANSFERVEN no ser&aacute; responsable, en
                            ning&uacute;n caso, por cantidades superiores a las
                            cobradas al cliente en la web, ni por ning&uacute;n
                            da&ntilde;o, directo o indirecto causado por el
                            retraso en la ejecuci&oacute;n o por la no
                            ejecuci&oacute;n o ejecuci&oacute;n parcial de una
                            orden registrada en su web, independientemente de
                            que estos da&ntilde;os hubieran sido causados por la
                            negligencia de uno de sus empleados o de sus
                            corresponsales extranjeros o por cualquier otra
                            raz&oacute;n.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            En caso de que un producto o servicio solicitado en
                            l&iacute;nea no pueda ser proporcionado en un margen
                            de tiempo razonable (3 d&iacute;as h&aacute;biles)
                            por fallos en el servicio gestionado por
                            TRANSFERVEN, TRANSFERVEN devolver&aacute; la
                            cantidad total cobrada al cliente por su orden
                            solicitada en la web de TRANSFERVEN.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            El contenido, programas, informaci&oacute;n y/o
                            consejos expresados en este Sitio deben entenderse
                            como simplemente orientativos. TRANSFERVEN no
                            responde de ninguna forma de la efectividad o
                            exactitud de los mismos, quedando exenta de
                            cualquier responsabilidad contractual o
                            extracontractual con los usuarios que hagan uso de
                            ellos, ya que son &eacute;stas las que
                            deber&aacute;n decidir seg&uacute;n su criterio la
                            oportunidad de los mismos.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              7.Uso del servicio de Env&iacute;o de Dinero
                              Online
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Para realizar el proceso, el cliente debe facilitar
                            los datos, y clicar en TRANSFERVEN, o una
                            expresi&oacute;n similar. Debes ir navegando por las
                            pantallas que te salgan, y rellenar la
                            informaci&oacute;n aportando los datos personales
                            necesarios. Si no te deja continuar, es porque
                            alg&uacute;n campo obligatorio est&aacute; mal
                            cumplimentado o en blanco. Puedes comprobar si los
                            datos son correctos y en su caso corregir aquellos
                            err&oacute;neos.&nbsp;
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Los env&iacute;os de dinero internaciones iniciados
                            en la web de TRANSFERVEN est&aacute;n sujetos a
                            tasas, l&iacute;mites y condiciones de pago que
                            dependen del pa&iacute;s de destino de los fondos.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Una tasa de cambio tambi&eacute;n resulta aplicable
                            a los env&iacute;os de dinero que se paguen en una
                            moneda distinta del EURO (&euro;) en el pa&iacute;s
                            de destino de los fondos. En estos casos, la
                            cantidad de la transacci&oacute;n ser&aacute;
                            convertida en la moneda de destino al momento de
                            iniciar la orden de env&iacute;o de dinero a
                            trav&eacute;s de la web de TRANSFERVEN y la cantidad
                            exacta a recibir por el beneficiario en la moneda
                            local se mostrar&aacute; en la orden.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            El usuario de la web puede informarse de las
                            condiciones aplicables a la operaci&oacute;n de
                            env&iacute;o de dinero, incluyendo las formas de
                            pago y las oficinas disponibles en el pa&iacute;s de
                            destino de los fondos mediante la simulaci&oacute;n
                            de una orden en la calculadora disponible en la web.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Adem&aacute;s de las tasas de la transacci&oacute;n,
                            TRANSFERVEN tambi&eacute;n mantiene un margen sobre
                            la tasa de cambio que se aplica a las operaciones de
                            env&iacute;o de dinero iniciadas desde la web.
                            TRANSFERVEN se reserva tambi&eacute;n el derecho a
                            aplicar tasas de cambio m&aacute;s favorables a las
                            transacciones iniciadas a trav&eacute;s de la web
                            que a aquellas iniciadas en locales o agentes
                            autorizados.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Mediante la validaci&oacute;n de un env&iacute;o de
                            dinero en l&iacute;nea, el cliente confirma que
                            acepta las condiciones financieras y operativas
                            aplicables a su transacci&oacute;n, indicadas en la
                            web. El usuario declara que sus datos personales,
                            as&iacute; como los proporcionados para el
                            beneficiario son precisos. TRANSFERVEN se reserva el
                            derecho a solicitar informaci&oacute;n adicional
                            sobre los usuarios de la web o cancelar una
                            transacci&oacute;n pendiente por cualquiera de las
                            razones indicadas en la secci&oacute;n 4. Una vez
                            comprobado y recibido el pago, se ejecuta el
                            env&iacute;o de dinero, pudiendo el beneficiario
                            recogerlo en destino. Todas las transacciones se
                            ejecutan bajo un servidor seguro.
                          </span>
                        </span>
                      </p>

                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            8.Desistimiento
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Si no queda satisfecho con los servicios, tiene
                            derecho a desistir del servicio. El plazo legal de
                            desistimiento es a los 14 d&iacute;as naturales. Sin
                            embargo, por la propia naturaleza inmediata del
                            servicio, no podemos atender este derecho una vez el
                            mismo est&eacute; ejecutado, ya que se abona en la
                            cuenta del tercero que usted haya se&ntilde;alado
                            como beneficiario.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Sin embargo, el cliente tiene derecho a cancelar una
                            orden de env&iacute;o de dinero pendiente, siempre
                            que no se haya pagado en el pa&iacute;s de destino
                            de los fondos. Para cancelar una orden pendiente, el
                            usuario debe iniciar sesi&oacute;n en su cuenta
                            personal, localizar la orden en su historial y
                            solicitar la cancelaci&oacute;n a trav&eacute;s del
                            bot&oacute;n &ldquo;cancelar&rdquo; disponible en la
                            web. Tambi&eacute;n se puede solicitar la
                            cancelaci&oacute;n poni&eacute;ndose en contacto con
                            nuestro servicio de atenci&oacute;n al cliente por
                            tel&eacute;fono o por email (detalles de contacto en
                            la secci&oacute;n 15).
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            En caso de que el cliente solicite la
                            cancelaci&oacute;n de una orden por un fallo en el
                            servicio por parte de TRANSFERVEN, TRANSFERVEN
                            devolver&aacute; al cliente la cantidad total
                            cobrada al cliente en el momento del procesamiento
                            de la orden (incluyendo las tasas); siempre que la
                            orden no se haya pagado al beneficiario. En
                            cualquier otro caso, TRANSFERVEN devolver&aacute; al
                            cliente la cantidad cobrada.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              9.Facultad de denegaci&oacute;n del Servicio
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            TRANSFERVEN se reserva la facultad de excluir o no
                            permitir el servicio, cuando considere que se
                            infringe la normativa vigente, nuestras
                            pol&iacute;ticas de blanqueo, las condiciones
                            generales o particulares, la moral, las costumbres
                            generalmente aceptadas, el orden p&uacute;blico,
                            cuando se perjudique a un tercero, o cuando por
                            razones derivadas de la propia imagen y
                            reputaci&oacute;n de la p&aacute;gina web
                            TRANSFERVEN no lo considere oportuno. En este caso,
                            si el pago se hubiera realizado, se proceder&aacute;
                            a su devoluci&oacute;n.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              10.Propiedad intelectual y derechos patrimoniales
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            TRANSFERVEN se reserva todos los derechos de
                            propiedad intelectual y patrimoniales sobre la web
                            de TRANSFERVEN en s&iacute; misma y sobre todos sus
                            componentes, incluyendo cualquier texto,
                            ilustraci&oacute;n, dise&ntilde;o, etc., publicado
                            en la misma. Los usuarios se benefician de una
                            licencia de uso no exclusiva para la web de
                            TRANSFERVEN, que les da derecho al acceso
                            &uacute;nicamente a los contenidos de la web de
                            TRANSFERVEN, a consultar la informaci&oacute;n
                            publicada en ella y al uso de los servicios
                            proporcionados en l&iacute;nea, de forma regular y
                            &uacute;nicamente para fines privados. Por otro
                            lado, ning&uacute;n derecho de propiedad intelectual
                            se transfiere de forma alguna al usuario de la web.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Cualquier extracci&oacute;n y/o reutilizaci&oacute;n
                            de todo o de parte del contenido de la web de
                            TRANSFERVEN, en cualquier forma o por cualquier
                            medio est&aacute; estrictamente prohibido sin el
                            consentimiento previo y expreso de TRANSFERVEN. De
                            la misma forma, cualquier marca, logo, etc.,
                            publicados en la web de TRANSFERVEN son propiedad
                            exclusiva de TRANSFERVEN y no pueden ser
                            reproducidos de ninguna forma sin el consentimiento
                            previo y expreso de TRANSFERVEN.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            La utilizaci&oacute;n no autorizada de la
                            informaci&oacute;n contenida en este Sitio,
                            as&iacute; como los perjuicios ocasionados en los
                            derechos de propiedad intelectual e industrial de
                            TRANSFERVEN, pueden dar lugar al ejercicio de las
                            acciones que legalmente correspondan y, si procede,
                            a las responsabilidades que de dicho ejercicio se
                            deriven.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              11.Enlaces a p&aacute;ginas web de terceros
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            TRANSFERVEN no proporciona ninguna garant&iacute;a y
                            no puede ser considerada responsable por los enlaces
                            creados por terceros a la web de TRANSFERVEN, o
                            inversamente embebidos en la web de TRANSFERVEN y
                            que lleven a webs de terceros, ni puede ser
                            considerada responsable por el contenido de las web
                            de terceros. Los enlaces referidos no indican de
                            ninguna forma que TRANSFERVEN est&eacute;
                            relacionada con las webs de terceros o que
                            &eacute;stos tengan autorizaci&oacute;n para el uso
                            del nombre comercial, el logo o los s&iacute;mbolos
                            de TRANSFERVEN. Los usuarios de internet reconocen
                            expresamente que ser&aacute;n los &uacute;nicos
                            responsables del uso que hagan de las p&aacute;ginas
                            web de terceros y declaran ser perfectamente
                            conscientes que estas webs de terceros se rigen por
                            otros T&eacute;rminos de Uso y Pol&iacute;tica de
                            Privacidad a los aplicables a la web de TRANSFERVEN.
                            Si se publican contenidos aportados por terceras
                            personas o empresas, TRANSFERVEN, no responde de la
                            veracidad y exactitud de los mismos, quedando exenta
                            de cualquier responsabilidad contractual o
                            extracontractual con los usuarios que hagan uso de
                            ellos. As&iacute; mismo, declina cualquier
                            responsabilidad por los servicios que eventualmente
                            pudieran prestarse en el Sitio por parte de
                            terceros. Si tienes conocimiento de que los sitios
                            enlazados remiten a p&aacute;ginas cuyos contenidos
                            o servicios son il&iacute;citos, nocivos,
                            denigrantes, violentos o contrarios a la moral; te
                            agradecer&iacute;amos que te pusieras en contacto
                            con nosotros.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              12.Cesi&oacute;n y subrogaci&oacute;n
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            TRANSFERVEN podr&aacute; contratar prestadores de
                            servicios, y colaborar o ceder los contratos
                            formalizados a terceras entidades, para llevar a
                            cabo la totalidad o parte de los servicios a que se
                            compromete en virtud a las diferentes operaciones
                            que se vayan formalizando.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              13.Extinci&oacute;n y resoluci&oacute;n
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            El presente contrato se extingue cuando las partes
                            cumplan con las obligaciones a las que se
                            comprometen en el mismo o cuando sea resuelto por
                            alguna de ellas si concurre alguna de las causas
                            previstas para la resoluci&oacute;n, o si la
                            contraparte incumple de forma grave alguna de las
                            obligaciones establecidas en el contrato. Sin
                            embargo, la cuenta quedar&aacute; activa para que el
                            usuario pueda hacer futuras transacciones.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            14.Datos de Contacto
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Cualquier comunicaci&oacute;n relativa a la web de
                            TRANSFERVEN deber&aacute; dirigirse preferentemente
                            a la direcci&oacute;n de correo electr&oacute;nico:
                            info.es@transferven.com.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            En caso de que la comunicaci&oacute;n se refiera a
                            una reclamaci&oacute;n, nuestro departamento de
                            Atenci&oacute;n al Cliente confirmar&aacute; la
                            recepci&oacute;n del mismo en un plazo de 24 horas y
                            har&aacute; todo lo posible para darle una respuesta
                            en un plazo de 14 d&iacute;as naturales.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Las solicitudes escritas deber&aacute;n dirigirse a
                            la direcci&oacute;n postal: TRANSFERVEN,&nbsp;
                          </span>
                        </span>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            C/Garcilaso De La Vega 21, Local 12, 38005. Santa Cruz de Tenerife, España.
                          </span>
                        </span>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>.</span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Los usuarios de la web pueden tambi&eacute;n
                            contactar a nuestro servicio de Atenci&oacute;n al
                            Cliente por tel&eacute;fono, de Lunes a Viernes de 9
                            am a 7 pm a trav&eacute;s del n&uacute;mero de
                            tel&eacute;fono: +34 91 993 13 12
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Cualquier comunicaci&oacute;n que contenga datos
                            personales, preguntas, comentarios o sugerencias que
                            sea remitida a TRANSFERVEN se considerar&aacute;
                            como no-confidencial, sujeta al deber de
                            discreci&oacute;n de TRANSFERVEN y a los derechos de
                            privacidad de los usuarios web. Respecto de las
                            provisiones legales, TRANSFERVEN tendr&aacute;
                            derecho a reutilizar, copiar, modificar o reproducir
                            en cualquier forma y por cualquier medio, cualquier
                            comunicaci&oacute;n recibida de los usuarios de la
                            web, durante 10 a&ntilde;os desde su
                            recepci&oacute;n.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              15.Protecci&oacute;n de Datos Personales
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Los datos de car&aacute;cter personal que se
                            comuniquen a TRANSFERVEN por parte de los usuarios
                            de la web ser&aacute;n procesados de acuerdo con los
                            t&eacute;rminos de nuestra&nbsp;
                          </span>
                        </span>
                        <a href="https://www.transferven.com/privacy" target="_blank">
                          <span style={{ fontFamily: "Arial, serif" }}>
                            <span>
                              <u>Pol&iacute;tica de Privacidad</u>
                            </span>
                          </span>
                        </a>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>.&nbsp;</span>
                        </span>
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              Al aceptar los presentes T&eacute;rminos de Uso,
                              el usuario de la web acepta tambi&eacute;n los
                              t&eacute;rminos de nuestra Pol&iacute;tica de
                              Privacidad que incluye el uso de Cookies de
                              TRANSFERVEN.
                            
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Para m&aacute;s informaci&oacute;n sobre las cookies
                            generadas por la web de TRANSFERVEN y su uso, les
                            referimos a nuestra&nbsp;
                          </span>
                        </span>
                        <a href="https://www.transferven.com/cookies/" target="_blank">
                          <span style={{ fontFamily: "Arial, serif" }}>
                            <span>
                              <u>Pol&iacute;tica de Uso de Cookies</u>
                            </span>
                          </span>
                        </a>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              16.Requisitos de Diligencia debida de los clientes
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Las leyes y reglamentos que rigen sobre la
                            provisi&oacute;n de servicios financieros, y en
                            particular la ley 10/2010 de prevenci&oacute;n de
                            blanqueo de capitales y financiaci&oacute;n del
                            terrorismo requieren a instituciones financieras
                            como TRANSFERVEN la verificaci&oacute;n de la
                            identidad de sus clientes. TRANSFERVEN, por lo
                            tanto, ser reserva el derecho a solicitar a sus
                            clientes, cuando sea aplicable, que proporcionen
                            documentos adicionales para su
                            identificaci&oacute;n, origen y/o destino de fondos
                            y prop&oacute;sito de las operaciones. TRANSFERVEN
                            podr&aacute; tambi&eacute;n suspender o cancelar
                            operaciones de pago en caso de que no pueda obtener
                            de sus clientes documentaci&oacute;n de soporte
                            suficiente para cumplir con sus obligaciones
                            legales.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              17.Leyes aplicables y autoridades competentes
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Estos T&eacute;rminos de Uso y cualesquiera otras
                            provisiones que rijan las relaciones contractuales
                            entre TRANSFERVEN y sus clientes est&aacute;n
                            sujetas a la legislaci&oacute;n espa&ntilde;ola. Al
                            gozar el consumidor de normativa de
                            protecci&oacute;n, podr&aacute; reclamar o demandar
                            desde el fuero de su domicilio. Al gozar el
                            consumidor de normativa de protecci&oacute;n,
                            podr&aacute; reclamar o demandar desde el fuero de
                            su domicilio.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Ambas partes se someten, con renuncia expresa a
                            cualquier otro fuero, a los Juzgados y Tribunales de
                            Las Palmas (Espa&ntilde;a), en los siguientes casos:
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Que la parte compradora tenga su domicilio fuera de
                            la Uni&oacute;n Europea y en dicho pa&iacute;s no
                            exista convenio bilateral o multilateral con
                            Espa&ntilde;a que impida la posibilidad de fijar la
                            sumisi&oacute;n expresa del fuero;
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Si se trata de una compraventa realizada por una
                            empresa que act&uacute;e en el marco de su actividad
                            empresarial o profesional, que deber&aacute;
                            habernos solicitado la contrataci&oacute;n del
                            servicio aparte.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            
                              18.Rectificaci&oacute;n de los t&eacute;rminos de
                              uso
                            
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            TRANSFERVEN se reserva el derecho a modificar los
                            presentes T&eacute;rminos de Uso, sin
                            notificaci&oacute;n previa. En caso de que los
                            T&eacute;rminos de Uso modificados aumenten los
                            derechos u obligaciones de los usuarios de la web,
                            TRANSFERVEN deber&aacute; notificar a los clientes
                            de estos cambios mediante una publicaci&oacute;n
                            clara y accesible en la web, un mes antes de la
                            entrada en vigor de los nuevos T&eacute;rminos de
                            Uso.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Los presentes T&eacute;rminos de Uso de la web de
                            TRANSFERVEN fueron modificados por &uacute;ltima vez
                            el 18 de abril de 2023 y entran en vigor el
                            d&iacute;a de su publicaci&oacute;n.
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                              19.Resoluci&oacute;n de Disputas en L&iacute;nea
                          
                          </span>
                        </span>
                      </p>
                      
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Seg&uacute;n lo dispuesto en el Reglamento UE
                            524/2013 sobre resoluci&oacute;n de litigios en
                            l&iacute;nea en materia de consumo (ODR - Online
                            Dispute Resolution), le informamos de que, como
                            consumidor, tiene a su disposici&oacute;n un
                            procedimiento para resolver los diversos litigios
                            derivados de la venta online de mercanc&iacute;as y
                            servicios en la UE.
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Tiene a su disposici&oacute;n la plataforma RLL
                            (resoluci&oacute;n de litigios en l&iacute;nea) de
                            la UE:
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            http://ec.europa.eu/consumers/odr/
                          </span>
                        </span>
                      </p>
                      <p align="JUSTIFY">
                        <span style={{ fontFamily: "Arial, serif" }}>
                          <span>
                            Puede consultar m&aacute;s informaci&oacute;n en el
                            siguiente enlace:&nbsp;
                          </span>
                        </span>
                        <a href="http://eur-lex.europa.eu/legal-content/ES/TXT/PDF/?uri=CELEX:32013R0524">
                          <span style={{ fontFamily: "Arial, serif" }}>
                            <span>
                              <u>
                                http://eur-lex.europa.eu/legal-content/ES/TXT/PDF/?uri=CELEX:32013R0524
                              </u>
                            </span>
                          </span>
                        </a>
                      </p>
                    </>
                  }
                />
              }
            />
            <Route path="/orderhistory" element={<OrderHistory />} />
          </>
        ) : (
          <>
            {" "}
            <Route
              path="/"
              element={
                <div
                  style={{
                    height: "100vh",
                    position: "fixed",
                    width: "100%",
                    background: "lightblue",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    top: 0,
                  }}
                >
                  <h1 className="fw-bold">Coming Soon!</h1>
                </div>
              }
            />
            <Route
              path="*"
              element={
                <div
                  style={{
                    height: "100vh",
                    position: "fixed",
                    width: "100%",
                    background: "lightblue",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    top: 0,
                  }}
                >
                  <h1 className="fw-bold">Coming Soon!</h1>
                </div>
              }
            />
          </>
        )}
      </Routes>
      <Footer />
    </>
  );
}

export default App;
