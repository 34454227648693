import React from "react";

const FAQS2 = () => {
  return (
    <div className="FAQS mt-10">
      <div className="canvas">
        <h1 className="text-center fw-bold">Preguntas Frecuentes</h1>
        <div className="accordion mt-5" id="accordionExample">

          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingSeven">
              <button
                className="accordion-button rounded fs-5"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                ¿Qué es Walletven?
              </button>
            </h2>
            <div
              id="collapseSeven"
              className="accordion-collapse collapse show"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Walletven te permite comprar USDT y enviarlos directamente a Venezuela o cualquier país del mundo hacia la wallet que tú decidas. Podrás disponer de tus activos a través de CriptoCajeros, Tarjetas y P2P de Exchanges o pagos directos a otras wallets.
              </div>
            </div>
          </div>


          <div className="accordion-item mt-3 rounded border-0 ">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                ¿Cómo comprar y enviar Criptomonedas?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Muy sencillo. Después de haber activado tu Cuenta Transferven, puedes usar nuestra calculadora para elegir la cantidad de Criptomonedas que quieres comprar e indicarnos dónde deseas recibirlas (wallet).
              </div>
            </div>
          </div>

          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                ¿Dónde recibo mis Criptomonedas?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                En la misma calculadora deberás indicarnos la dirección de tu wallet para que puedas recibirlas allí y disponer de ellas en Venezuela o cualquier parte del mundo.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                ¿Por qué debo acreditar mi identidad?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Como empresa regulada, estamos obligados a verificar la identidad de todos los usuarios en operaciones no presenciales, empleando procedimientos seguros de identificación. Este proceso se realiza solo una vez al crear una Cuenta Transferven nueva.
              </div>
            </div>
          </div>


          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                ¿No tienes wallet?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Contacta con Atención Personalizada aquí y te ayudaremos a crear una. <a href="https://wa.me/34620818181" target="_blank">AQUÍ</a>
              </div>
            </div>
          </div>

      
          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingEight">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                ¿Cómo contacto con Atención al Cliente?
              </button>
            </h2>
            <div
              id="collapseEight"
              className="accordion-collapse collapse"
              aria-labelledby="headingEight"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Ponemos a tu disposición un Chat Online  <a href="https://wa.me/34620818181" target="_blank">
                  AQUÍ
                </a>, una sección para enviar Emails desde nuestra web y un Servicio Telefónico de Atención al Cliente al +34 91 993 13 12.
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default FAQS2;
